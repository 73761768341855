.headerSelectButton__button {
  margin-left: auto;
  margin-right: 0;
  max-width: 175px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;

  span {
    color: #323232;
    text-align: center;
  }

  &:hover {
    span {
      color: #e26c2d;
    }
  }
}

.headerSelectButton__button-active {
  span {
    color: #333333;
  }
}
