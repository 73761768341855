$font-family: 'Rubik';

.main-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  padding: 8px 15px;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height: 100%;
  z-index: 100;
}

@media (max-width: 900px) {
  .main-header {
    background: #f9f9f9;
  }
}

.main-header__left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-header__right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-self: end;
}

.main-header__search {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 40px;
  padding: 0px 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fafafa;

  span {
    color: #999;
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 1;
  }
}

.main-header__search-button-icon {
  display: flex;
  align-items: center;
}

.main-header__categories {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 51px;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  background: #c29b9b;
  overflow-x: auto;
  gap: 2px;
  background-color: #f8f9fa;
}

.main-header__box-shadow {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.main-header__categories__fixed-position {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -1;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.main-header__categories__fixed-position__visible {
  z-index: 10;
  opacity: 1;
}

.main-header__fixed-position {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.main-header__fixed-position__visible {
  z-index: 10;
  opacity: 1;
  z-index: 10;
}

.main-header__categories::-webkit-scrollbar {
  height: 0;
  width: 0;
  display: none;
}

.main-header__categories-link {
  color: #333;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  padding: 7.5px 12px;
  :first-child {
    padding-left: 0;
  }
}

.main-header__active {
  margin: 7.5px 0;
  padding: 6px 8px;
  background-color: #f8f2ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #f26b26;
  > span {
    color: var(--primary-primary) !important;
  }
}
.banner {
  text-align: center;
  background-color: #227944;
  padding: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .main-header__categories {
    background-color: #f9f9f9 !important;
    height: 44px;
    overflow-y: hidden;

    border-bottom: 1px solid var(--grays-gray-400);
  }
}
