.not_found {
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--secondary-border-1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  span {
    text-align: center;
  }
}

.products {
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  overflow: hidden;
  gap: 5px;
  background-color: rgb(249, 249, 249);
}
.loading_container {
  background-color: rgb(249, 249, 249);
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.8);
  inset: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s ease;
}

.overlay_show {
  opacity: 1;
  z-index: 999999999;
}

.back_pagination_container {
  background-color: rgb(249, 249, 249);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;

  > button {
    width: 125px;
    height: 22px;
  }
}
