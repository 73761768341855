.box {
  border: 1px solid #e1e1e1;
  border-radius: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 30px;
  gap: 4px;
  width: 100%;
  overflow-y: hidden;
  cursor: pointer;
}

.fast-icon {
  height: 27px;
  rotate: 180deg;
  transition: rotate 0.3s ease;
}
.fast-icon-open {
  rotate: 0deg;
}
