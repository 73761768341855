.navigation {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: var(--default-white);
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px;

  svg {
    display: flex;
  }

  &:hover {
    svg {
      path {
        fill: var(--primary-primary);
      }
    }
  }
}

.right {
  right: -10px !important;
}

.left {
  left: -10px !important;
}

@media (max-width: 900px) {
  .right {
    right: 0;
  }

  .left {
    left: 0;
  }
}

.placeholder_container {
  position: relative;
  padding: 0 15px;

  .placeholder_container {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    gap: 5px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
