.story_placeholder_container {
  display: flex;
  gap: 14px;
  overflow: hidden;
  padding: 0 10px;
  min-height: 152.59px;
  .story_placeholders {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .story_placeholder {
      width: 100px !important;
      height: 100px !important;
      border-radius: 50%;
    }
    .story_placeholder_text {
      width: 100px !important;
      height: 12px !important;
      border-radius: 20px;
    }
  }
}

.banner_placeholders {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;

  .banner_placeholder {
    width: 100% !important;
    height: 385px !important;
  }
}

@media (max-width: 570px) {
  .banner_placeholders {
    padding: 0 10px;
  }
}

@media (max-width: 500px) {
  .story_placeholder_container {
    min-height: 116.52px;

    .story_placeholders {
      .story_placeholder {
        width: 70px !important;
        height: 70px !important;
        border-radius: 50%;
      }
      .story_placeholder_text {
        width: 70px !important;
        height: 10px !important;
      }
    }
  }
}
