.placeholder {
  display: flex;
  gap: 24px;
  align-items: center;
  overflow: hidden;
  width: 100%;
  > div {
    height: 14px !important;
  }
}

@media (max-width: 576px) {
  .placeholder {
    > div {
      height: 17px !important;
    }
  }
}
