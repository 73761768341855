.breadcrumb {
  display: flex;
  align-items: center;

  padding: 10px 0;
}

.breadcrumbItem {
  padding: 0;
  margin: 0;
  line-height: 1;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  font-size: 9px;
  color: var(--primary-primary);
  margin: 0 15px;
  max-width: 30px;
}

.breadcrumb_placeholder {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  height: 38.18px;
}

@media (max-width: 576px) {
  .breadcrumb_placeholder {
    padding: 20px 12px;
  }
}

@media (max-width: 900px) {
  .breadcrumb {
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding: 15px;

    .shadow {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(100%, rgba(255, 255, 255, 0)),
        color-stop(0%, #fff)
      );
      z-index: 1;
      position: absolute;
      border-bottom-right-radius: 8px;
      height: 25px;
      pointer-events: none;
      left: 0;
      width: 30px;
    }
  }

  .breadcrumb::-webkit-scrollbar {
    height: 0;
    width: 0;
    display: none;
  }

  .breadcrumbItem {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 0;
    margin: 0;

    span {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .separator {
    margin: 3px 15px;
  }
}
