.scroll-top {
  backface-visibility: hidden;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-bottom-width: 2px;
  bottom: 20px;
  cursor: pointer;
  height: 40px;
  line-height: 42px;
  margin: 5px;
  position: fixed;
  right: 20px;
  width: 110px;
  will-change: scroll-position;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 1000;
  opacity: 1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  transition: all 0.1s ease-in;

  &:hover {
    border-color: var(--primary-primary);
    span {
      color: var(--primary-primary) !important;
    }
    svg {
      path {
        fill: var(--primary-primary) !important;
        color: var(--primary-primary) !important;
      }
    }
  }

  &_hide {
    display: none;
  }
}
