.scroll-top {
  align-items: center;
  background: rgba(49, 49, 49, 0.7);
  border-radius: 50%;
  bottom: 20px;
  display: flex;
  font-size: 1.5rem;
  height: 46px;
  justify-content: center;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 46px;
  z-index: 5000;
  cursor: pointer;
  border: 1px solid transparent;

  &_hide {
    display: none;
  }
}
