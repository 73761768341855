.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 5px;
  width: 100%;
  padding: 5px;
  margin-top: 5px;

  .placeholder-item {
    > div {
      border-radius: 8px 8px 0 0;
    }
  }
}
