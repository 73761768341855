.fast-container {
  border: 1px solid #e1e1e1;
  border-radius: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 30px;
  gap: 4px;
  width: fit-content;
  min-width: fit-content;
  overflow-y: hidden;
  cursor: pointer;

  .fast-icon {
    height: 27px;
    rotate: 180deg;
    transition: rotate 0.3s ease;
  }
  .fast-icon-open {
    rotate: 0deg;
  }

  .dropdown-hide {
    visibility: hidden;
  }

  .handler {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.dropdown-container {
  width: 100%;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  height: 269px;
  top: 161px;
  z-index: 200;
  left: 0;
  border-bottom: 1px solid #e1e1e1;
}

.selected-dropdown {
  border-color: var(--primary-primary);

  span {
    color: var(--primary-primary);
  }
}

.overlay {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: 0.5;
  z-index: 199;
  overflow-x: hidden;
  height: calc(100dvh - 294px);
  transition: all 0.5s ease;
}
