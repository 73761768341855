.customSelect {
  position: relative;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  > label {
    margin-bottom: 4px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    gap: 8px;
    flex: 1;
  }

  .icon-right {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.selectHeader {
  display: flex;
  align-items: center;
  width: 200px;
  padding: 6px 12px;
  height: 100%;
  width: 100%;
  border: 1px solid var(--grays-gray-400);
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--default-white);

  .chevron {
    display: flex;
    justify-content: flex-end;
  }

  .chevron_rotate {
    rotate: 180deg;
  }
}

.error {
  border: 1px solid var(--reds-errors-red-500) !important;
  background-color: #fff1f1 !important;
}

.selectedOption {
  display: flex;
  align-items: center;
  width: 100%;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid var(--grays-gray-400);
  background-color: #fff;
  border-radius: 1px;
  padding: 8px 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

.option {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  height: 25.25px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--primary-primary-light);
  }

  .text {
    flex: 3;
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 1;
  }
}

.loading {
  animation: rotate 2s linear infinite;
  z-index: 100;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .customSelect {
    height: unset !important;
  }
  .selectHeader {
    height: 36px !important;
    background-color: #fafafa;
  }

  .optionsContainer {
    height: 110px;
    background-color: #fafafa;
  }

  .icon {
    span {
      margin: 0 !important;
    }
  }
}

// modal mode styles

.modal_container {
  z-index: 3000;
}

.modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  max-height: 280px;
}

.backdrop {
  z-index: 2999;
}

.slide-down {
  animation: slide-down 0.4s ease-out forwards;
}

.modal .modal-dialog {
  margin: 0;
}

.content {
  height: 100%;
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.2s ease-out forwards;
  overflow-x: visible;

  .header {
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }

  .dp_content {
    height: 250px;
    text-align: left;
    overflow-y: scroll;

    .search {
      background-color: #e6e6e6;
      display: flex;
      padding: 15px;
      position: relative;

      .icon {
        color: #999;
        margin: 2px 10px;
        position: absolute;
        z-index: 1;
      }
      .input_wrapper {
        width: 100%;
        border-radius: 4px;
        display: block;
        text-align: left;

        .input {
          background-color: #fff;
          height: 30px;
          position: relative;
          width: 100%;
          padding-left: 35px;
        }
      }
    }

    .inner_content {
      padding: 10px 12px 0px 12px;
    }

    .selected {
      position: absolute;
      left: -12px;
      top: -4px;
    }
  }
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

@media (max-width: 500px) {
  .option {
    margin-bottom: 12px !important;
    padding: 0;
    padding-bottom: 3px;
  }
}
