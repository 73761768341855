.multi-select-wrapper {
  height: 100%;
  .input-container {
    margin-top: 15px;
    padding: 0 15px;
    margin-bottom: 15px;
  }
  .items {
    overflow: auto;
    overflow-x: hidden;
    height: 129px;
    padding: 0 15px;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-auto-rows: max-content;
    grid-gap: 17px;
    .item {
      height: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .input {
        background-color: var(--grays-gray-100);
        cursor: pointer;
      }
    }
  }
  .mobile-actions {
    display: flex;
    border-top: 1px solid #e1e1e1;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 76px;
    padding: 0 15px;
  }
}
