.fast-filters {
  border-bottom: 1px solid var(--secondary-border-1);
  padding: 5px 15px;
  background-color: #fff;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  z-index: 500;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.25);
  overflow: visible;
}

.tags {
  height: 24px;
  margin: 15px 0;
}

.sort {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.slide-down {
  animation: slide-down 0.4s ease-out forwards;
}

.sort .modal-dialog {
  margin: 0;
}

.sort__content {
  height: 100%;
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.2s ease-out forwards;

  &_header {
    padding: 10px 15px;
    border-bottom: 1px solid var(--secondary-border-1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_close {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_items {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    padding-bottom: 10px;

    &_item {
      padding: 5px 15px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}
