.breadcrumb {
  margin: 20px 0;
}

.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  /* Change to column for mobile */
  justify-content: flex-start;
  width: 100%;

  .filters {
    display: flex;
    flex-direction: column;
  }

  .products {
    flex: 1;

    .header {
      &_titleWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* Change to column for mobile */
        margin-bottom: 20px;
      }

      &_selectBox {
        width: 100%;
        max-width: 200px;
        height: 38px;
      }

      &_tags {
        display: flex;
        flex-wrap: wrap;
        /* Allow tags to wrap */
        gap: 10px;
        /* Adjust gap */
        height: auto;
        /* Adjust height */
      }

      &_badge {
        height: auto;
        /* Adjust height */
        width: auto;
        /* Adjust width */
      }
    }

    hr {
      margin: 20px 0;
      /* Adjust margin */
    }

    .products-wrapper {
      padding-bottom: 30px;

      .not_found {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 12px;
      }

      .products-container {
        display: grid;
        overflow: hidden;
        grid-template-columns: repeat(auto-fit, minmax(0, 230px));
        gap: 26px;
        margin-bottom: 26px;
      }
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.8);
  inset: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s ease;
}

.overlay_show {
  opacity: 1;
  z-index: 999999999;
}

.back_pagination_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 26px;
  > button {
    width: fit-content;
  }
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    /* Change back to row for larger screens */
    gap: 52px;
    /* Adjust gap */
  }

  .products .header {
    &_titleWrapper {
      flex-direction: row;
      /* Change back to row for larger screens */
    }
  }

  .products .header .header_title_h1 {
    font-size: 24px;
    /* Adjust font size for larger screens */
  }
}
