.header-categories {
  display: flex;
  justify-content: space-between;
}

.header-categories__item {
  position: relative;
}

.header-categories__item-title {
  display: flex;
  align-items: center;
  gap: 7.5px;

  span {
    color: #333;
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
}

.header__link--desktop-item {
  position: relative;
}

.header__link--desktop-item__icon {
  pointer-events: none;
  position: absolute;
  padding-top: 10px;
  top: 0px;
  z-index: 11;
  left: calc(100% - 9px);
}

.header-categories__item-title-active {
  span {
    color: #e26c2d;
  }
}

.header-categories__item__sub-items {
  position: relative;
  display: flex;
}

.header-categories__item__sub-items__wrapper {
  padding-top: 12px;
  position: absolute;
  z-index: 10;
  left: -13px;
}

.header-categories__item__sub-items__wrapper__item {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: 0.2px solid rgba(51, 51, 51, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 20px;
  width: 288px;
}

.header-categories__item__sub-items__wrapper__item__title {
  color: #333;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    color: #e26c2d;
  }
}
