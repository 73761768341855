.back {
  border-radius: 8px;
  background: var(--Primary-Primary, #f26b26);
  display: flex;
  width: 27px;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
